<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h3 class="display-3">Программы</h3>
                        <b-list-group>
                            <program-card
                                v-for="item in programs"
                                :key="item.title"
                                :title="item.title"
                                :body="item.body"
                                :link="item.link"
                            />
                        </b-list-group>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import ProgramCard from './components/ProgramCard.vue';

export default {
    name: "Programs",
    components: {
        ProgramCard
    },
    data () {
        return {
            programs: [
                {
                    title: 'NoteWorthy Composer 2.5',
                    body: 'Программа для набирания нот в компьютер. Не дорогая, основные возможности.\
                           Сокращение: NWC',
                    link: 'https://noteworthycomposer.com/info/evaluate.htm'
                },
                {
                    title: 'NoteWorthy Composer 2.5 Viewer',
                    body: 'Программа для просмотра .nwc фалов\
                           Программа бесплатная',
                    link: 'https://noteworthycomposer.com/nwc2/viewer.htm'
                },
                {
                    title: 'Sibelius',
                    body: 'Программа для набирания нот в компьютер. Дорогая, все возможности по музыке.',
                    link: 'https://www.avid.com/sibelius'
                },
                {
                    title: 'Sibelius Scorch',
                    body: 'Программа для просмотра .sib файлов',
                    link: 'https://connect.avid.com/ScorchDownload.html'
                },
                {
                    title: '7-Zip',
                    body: 'Программы чтобы открыть .zip файлы',
                    link: 'https://www.7-zip.org/'
                }
            ]
        };
    }
};
</script>